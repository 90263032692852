/* eslint-disable react/prop-types */
import Image from "next/image";
import { Fragment } from "react";
import style from "./tab.module.scss";
import parse from "html-react-parser";

type TabProps = {
  click: () => void;
  text: string;
  disable: boolean;
  icon: string;
};

const Tab: React.FC<TabProps> = ({ click, text, disable, icon }) => {
  const altText = text.replaceAll(/<\/?b>/g, "");
  return (
    <Fragment>
      <button onClick={click} className={style.btnTab} disabled={disable}>
        <span className={style.bgImage}>
          <Image src={icon} width={25} height={25} alt={altText} />
        </span>
        <span className={style.tabText}>{parse(text)}</span>
      </button>
    </Fragment>
  );
};

export default Tab;
