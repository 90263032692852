import React, { Fragment, useState } from "react";
import style from "./verticalTabs.module.scss";
import Tab from "./tab/component";
import TabContent from "./tabContent/component";
import parse from "html-react-parser";

type TabButtonProps = {
  id: string;
  text: string;
  img: string;
};

type TabContentProps = {
  title?: string;
  idVideo?: string;
  button?: boolean;
  buttonCreateAccount?: boolean;
  btnUrl: string;
  btnText: string;
  description?: string;
  smallText?: string;
};

type ComponentProps = {
  btn: TabButtonProps;
  content: TabContentProps;
};

type VerticalTabsProps = {
  tabs: ComponentProps[];
  title: string;
};

const VerticalTabs: React.FC<VerticalTabsProps> = ({ tabs, title }) => {
  const [activeTab, setActiveTab] = useState<string | null>(tabs[0].btn.id);
  const handleClick = (videoId: string) => {
    setActiveTab(videoId);
  };

  return (
    <div className={style.blockVerticalTabs}>
      <h2>{parse(title)}</h2>
      <div className={style.containerVerticalTabs}>
        <div className={style.buttons}>
          <ul>
            {tabs.map((t, index) => (
              <li key={index}>
                <Tab
                  click={() => handleClick(t.btn.id)}
                  icon={t.btn.img}
                  text={t.btn.text}
                  disable={activeTab === t.btn.id}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className={style.content}>
          {tabs.map((c, index) => (
            <Fragment key={index}>{activeTab === c.btn.id && <TabContent data={c.content} />}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalTabs;
