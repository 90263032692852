import { TWpGlobal } from "@@types/wordPress";
import { imgDefault } from "@constants";
import parse from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment } from "react";
import style from "./cardPost.module.scss";

type CardPostProps = {
  data: TWpGlobal[];
  img: boolean;
  category?: number;
  quantity?: number;
  omitFirst?: boolean;
};

const CardPost: React.FC<CardPostProps> = ({ data, img, quantity, omitFirst, category }) => {
  const filterCategory = data.filter((f) => (category ? f.categories.includes(category) : true));
  const filterSlide = omitFirst ? filterCategory : filterCategory.slice(1, quantity);
  const { pathname } = useRouter();
  const filterRoute = pathname.replace("/[slug]", "").trim();

  return (
    <Fragment>
      {filterSlide.map((b, index) => (
        <article key={index} className={style.card}>
          <Link href={`${filterRoute}/[slug]`} as={`${filterRoute}/${b.slug}`} className={style.cardLink}>
            {img ? (
              <Image
                src={!b.uagb_featured_image_src.large[0] ? imgDefault : (b.uagb_featured_image_src.large[0] as string)}
                width={393}
                height={200}
                alt={`${parse(b.title.rendered)}`}
              />
            ) : null}
            <h2> {parse(b.title.rendered)} </h2>
            <div dangerouslySetInnerHTML={{ __html: b.excerpt.rendered.slice(0, 65) + "..." }} />
          </Link>
        </article>
      ))}
    </Fragment>
  );
};

export default CardPost;
